<template>
  <CustomLoader v-if="loading" />

  <template v-else>
    <div class="card mb-5 mb-xxl-8 py-0 bank-settings">
      <div class="card-body pt-6 px-0 pb-0">
        <div class="d-flex flex-wrap flex-sm-nowrap mb-5 settings-section">
          <div class="me-7 mb-4 d-flex justify-content-center justify-content-sm-start">
            <div class="symbol symbol-100px symbol-lg-160px symbol-fixed position-relative">
              <img :src="userInfo.icon_path" alt="image" />
            </div>
          </div>
          <div class="flex-grow-1">
            <div class="d-flex justify-content-between align-items-start flex-wrap mb-2 flex-column flex-sm-row align-items-center">
              <div class="d-flex flex-column">
                <div class="d-flex align-items-center justify-content-center justify-content-sm-start mb-2">
                  <a href="#" class="text-gray-800 text-hover-primary fs-2 fw-bolder me-1">{{userInfo.getFullName()}}</a>
                </div>

                <div class="d-flex flex-wrap align-items-center flex-column flex-sm-row fw-bold fs-6 mb-4 pe-2">
                  <p class="d-flex align-items-center text-gray-400 me-5 mb-2">
                    <img src="/media/buying/icons/business.svg" class="me-2" alt="">
                    {{ userDetails.bank_name }}
                  </p>
                  <a class="d-flex align-items-center text-gray-400 me-5 mb-2">
                    <img src="/media/buying/icons/user.svg" class="me-2" alt="">
                    {{ userInfo.job_title }}
                  </a>
                  <a class="d-flex align-items-center text-gray-400 me-5 mb-2">
                    <img src="/media/buying/icons/email.svg" class="me-2" alt="">
                    {{ userInfo.email }}
                  </a>
                </div>
              </div>

              <div class="d-flex">
<!--                <div class="me-0">-->
<!--                  <el-dropdown trigger="click" placement="bottom-end">-->
<!--                    <span class="el-dropdown-link">-->
<!--                      <button class="btn btn-sm btn-icon btn-bg-transparent btn-active-color-primary">-->
<!--                        <i class="bi bi-three-dots fs-1 fw-bolder"></i>-->
<!--                      </button>-->
<!--                    </span>-->
<!--                    <template #dropdown>-->
<!--                      <el-dropdown-menu>-->
<!--                        <el-dropdown-item>{{$t('Contact Support')}}</el-dropdown-item>-->
<!--                        <el-dropdown-item>{{$t('FAQ & Guides')}}</el-dropdown-item>-->
<!--                        <el-dropdown-item>{{$t('How to Videos / Guides')}}</el-dropdown-item>-->
<!--                        <el-dropdown-item>{{$t('View Privacy Policy')}}</el-dropdown-item>-->
<!--                      </el-dropdown-menu>-->
<!--                    </template>-->
<!--                  </el-dropdown>-->
<!--                </div>-->
              </div>
            </div>

            <div class="d-flex flex-wrap flex-stack mt-md-8">
              <div class="d-flex flex-column flex-grow-1">
                <div class="d-flex flex-wrap user-counter">
                  <template v-for="(item, index) in counter">
                    <div :class="`border border-gray-300 rounded min-w-175px py-3 px-4 mb-3 ${item.statusClass} ${(index+1) !== counter.length ? 'me-6' : ''}`">
                      <div class="d-flex flex-column align-items-center">
                        <div class="fw-bold fs-6 text-gray-400">{{ $t(item.label) }}</div>
                        <div class="fs-1 fw-boldest">{{ item.value }}</div>
                      </div>
                    </div>
                  </template>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="d-flex overflow-auto h-55px">
          <ul class="nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap">
            <li class="nav-item" v-for="(item, index) in navLinks" :key="index">
              <router-link
                :to="item.link"
                class="nav-link text-active-primary mx-8 fs-4"
                active-class="active"
              >{{ $t(item.label) }}
              </router-link>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <router-view :userDetails="userDetails" @onCountryFollow="handleCountryFollow"></router-view>
  </template>
</template>

<script>
import store from "@/store";
import UserProfileHeader from "@/buying-teams/shared-components/settings/UserProfileHeader";
import {setCurrentPageBreadcrumbs} from "@/core/helpers/breadcrumb";
import {BankUserSettingsContainer} from "@/store/models/bank/BankUserSettingsContainer";
import CustomLoader from "@/buying-teams/shared-components/utils/CustomLoader";
import {diagLog, getStatusClass} from "@/core/helpers/GlobalHelper";

export default {
  name: "UserSettings",
  components: {CustomLoader, UserProfileHeader},
  data() {
    return {
      userDetails: null,
      loading: true,
      navLinks: [
        {
          label: 'Overview',
          link: '/bank/settings/user-settings/overview'
        },
        {
          label: 'Settings',
          link: '/bank/settings/user-settings/settings'
        },
        {
          label: 'Followed Countries',
          link: '/bank/settings/user-settings/followed-countries'
        },
        {
          label: 'Followed Product Areas',
          link: '/bank/settings/user-settings/followed-product-areas'
        },
        // {
        //   label: 'Notifications',
        //   link: '/bank/settings/user-settings/notifications'
        // },
        // {
        //   label: 'Sharing Policy',
        //   link: '/bank/settings/user-settings/sharing-policy'
        // }
      ]
    }
  },
  computed:{
    bank() {
      return store.getters.bank;
    },
    userInfo(){
      return store.getters.currentUser
    },
    authUserProfileInfo() {
      return store.getters.authUserProfileInfo;
    },
    counter() {
      return [
        {
          label: 'Average Score',
          statusClass: 'average_score',
          value: this.userDetails ? this.userDetails.score : 0
        },
        {
          label: 'Followed Countries',
          statusClass: '',
          value: this.userDetails ? this.userDetails.followed_countries.length : 0
        },
        {
          label: 'Followed Product Areas',
          statusClass: '',
          value: this.userDetails ? this.userDetails.followed_product_areas.length : 0
        }
      ];
    }
  },
  async mounted() {
    setCurrentPageBreadcrumbs({
      title: this.bank.name,
      logo: this.bank.icon_path
    });
    this.loading = true;
    store.dispatch('fetchBankUserData', this.userInfo.id)
    .then( async (res) => {
      await store.dispatch('fetchBankUserSettings');
      this.userDetails = new BankUserSettingsContainer(res, this.userInfo);
    })
    .finally(() => {
      this.loading = false;
    })
  },
  methods: {
    handleCountryFollow(country) {
      diagLog('emitter', country);
      // TODO add API Call
      // this.userDetails.followed_countries.push(country)
    }
  }
}
</script>
<style lang="scss" scoped>
.nav-stretch .nav-item {
  &:first-child a {
    margin-left: 18px !important;
  }
}
.user-counter {
  .border {
    &.status-danger {
      border-color: #E22D21 !important;
      .fw-boldest {
        color: #E22D21;
      }
    }
    &.status-warning {
      border-color: #FAD03B !important;
      .fw-boldest {
        color: #FAD03B;
      }
    }
    &.status-success {
      border-color: #2BC490 !important;
      .fw-boldest {
        color: #2BC490;
      }
    }
  }
}
.bank-settings {
  .nav-line-tabs .nav-item .nav-link {
    white-space: pre;
  }
  .user-counter {
    .average_score {
      position: relative;
      margin-right: 39px !important;
      &:before {
        content: "";
        position: absolute;
        width: 1px;
        height: 100%;
        background: #000000;
        top: 0;
        right: -20px;
        opacity: 0.1;
      }
    }
  }
  @media (max-width: 685px) {
    .settings-section .user-counter {
      margin-top: 0;
    }
  }
  @media (max-width: 576px) {
    .user-counter  > div.average_score,
    .user-counter  > div {
      margin-right: 0 !important;
      width: 100%;
      &:before {
        display: none;
      }
    }
  }
}
</style>
