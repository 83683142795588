<template>
  <div class="card mb-5 mb-xxl-8 py-0 business-user-settings">
    <div class="card-body pt-6 px-0 pb-0">
      <div class="d-flex flex-wrap flex-sm-nowrap mb-5 settings-section">
        <div class="me-sm-7 mb-4 d-flex justify-content-center justify-content-sm-start">
          <div class="symbol symbol-100px symbol-lg-160px symbol-fixed position-relative">
            <img :src="userInfo.icon_path" alt="image" />
          </div>
        </div>

        <div class="flex-grow-1">
          <div class="d-flex justify-content-between align-items-start flex-wrap mb-2 flex-column flex-sm-row align-items-center">
            <div class="d-flex flex-column">
              <div class="d-flex align-items-center justify-content-center justify-content-sm-start mb-2">
                <a href="#" class="text-gray-800 text-hover-primary fs-2 fw-bolder me-1">{{userInfo.getFullName()}}</a>
              </div>

              <div class="d-flex flex-wrap justify-content-center justify-content-sm-start fw-bold fs-6 mb-4 pe-2">
                <a class="d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2">
                  {{ userInfo.job_title }}
                </a>
              </div>
            </div>

            <div class="d-flex">
              <slot name="right-menu"></slot>
            </div>
          </div>
          <div class="d-flex flex-wrap flex-stack mt-md-8">
            <div class="d-flex flex-column flex-grow-1">
              <div class="d-flex flex-wrap user-counter">
                <template v-for="(item, index) in counter">
                  <div :class="`border border-gray-300 rounded min-w-175px py-3 px-4 mb-3 ${item.statusClass} ${(index+1) !== counter.length ? 'me-6' : ''}`">
                    <div class="d-flex flex-column align-items-center">
                      <div class="fw-bold fs-6 text-gray-400">{{ $t(item.label) }}</div>
                      <div class="fs-1 fw-boldest">
                        <CustomLoader v-if="isStatsLoading" />
                        <span v-else>
                          <template v-if="item.key === 'score'">{{ numberDecimalsFormat(item.value) }}</template>
                          <template v-else>{{ item.value }}</template>
                        </span>
                      </div>
                    </div>
                  </div>
                </template>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="d-flex overflow-auto h-55px">
        <ul class="nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap">
          <li class="nav-item" v-for="(item, index) in navLinks" :key="index">
            <router-link
              :to="item.link"
              class="nav-link text-active-primary mx-8 fs-4"
              active-class="active"
            >{{ $t(item.label) }}
            </router-link>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import CustomLoader from "@/buying-teams/shared-components/utils/CustomLoader";
import { numberDecimalsFormat } from "@/core/helpers/GlobalHelper";
export default {
  name: "UserProfileHeader",
  components: { CustomLoader },
  props: {
    userInfo: Object,
    navLinks: Array,
    counter: Array,
    profileInfo: Object,
    isStatsLoading: {
      type: Boolean,
      default: true,
    }
  },
  data() {
    return {
      numberDecimalsFormat
    };
  }
}
</script>

<style scoped lang="scss">
.symbol.symbol-lg-160px.symbol-fixed > img {
  object-fit: cover;
  object-position: top;
}
.nav-stretch .nav-item {
  &:first-child a {
    margin-left: 18px !important;
  }
}
.business-user-settings {
  .user-counter {
    .average_score {
      position: relative;
      margin-right: 39px !important;
      &:before {
        content: "";
        position: absolute;
        width: 1px;
        height: 100%;
        background: #000000;
        top: 0;
        right: -20px;
        opacity: 0.1;
      }
    }
  }
  @media (max-width: 685px) {
    .settings-section .user-counter {
      margin-top: 0;
    }
  }
  @media (max-width: 576px) {
    .user-counter  > div.average_score,
    .user-counter  > div {
      margin-right: 0 !important;
      width: 100%;
      &:before {
        display: none;
      }
    }
  }
}
</style>
