import { UserModel } from "@/store/models/UserModel";
import BankFollowOperationEnum from "@/store/enums/bank/BankFollowOperationEnum";

export class BankUserSettingsContainer {
  bank_name: string;
  countries: any[];
  bank_countries: any[];
  product_areas: any[];
  bank_product_areas: any[];
  followed_countries: string[];
  followed_product_areas: string[];
  score: string;
  userInfo: UserModel;

  constructor(obj, userInfo: UserModel) {
    this.bank_name = obj.bank_name;
    this.countries = this.convertObjectToArray(obj.countries);
    this.bank_countries = this.convertObjectToArray(obj.bank.countries);
    this.product_areas = this.convertObjectToArray(obj.product_areas);
    this.bank_product_areas = this.convertObjectToArray(obj.bank.product_areas);
    this.followed_countries = this.getFollowed(obj.countries);
    this.followed_product_areas = this.getFollowed(obj.product_areas);
    this.score = obj.score;
    this.userInfo = userInfo;
  }

  get countriesAndProductAreasData() {
    return {
      allCountries: this.bank_countries.map((c) => c.name),
      allProductAreas: this.bank_product_areas.map((c) => c.name),
      selectedCountries: this.followed_countries,
      selectedProductAreas: this.followed_product_areas,
    }
  }

  convertObjectToArray(value: any) {
    const data: any[] = [];
    for(let [key, item] of Object.entries(value)) {
      data.push({
        name: key,
        ...(item as any),
      })
    }
    return data;
  }

  getFollowed(data: any): string[] {
    if (data) {
      return Object.keys(data)
    }
    return [];
  }

  addRemoveCountry(operation: string, country: string) {
    if (operation === BankFollowOperationEnum.ADD) {
      this.followed_countries.push(country)
    } else if (operation === BankFollowOperationEnum.REMOVE) {
      this.followed_countries.splice(this.followed_countries.indexOf(country), 1);
    }
  }

  addRemoveProductArea(operation: string, productArea: string) {
    if (operation === BankFollowOperationEnum.ADD) {
      this.followed_product_areas.push(productArea)
    } else if (operation === BankFollowOperationEnum.REMOVE) {
      this.followed_product_areas.splice(this.followed_product_areas.indexOf(productArea), 1);
    }
  }
}
